import { makeGetLocalPreselectionFromBreadcrumbs } from '../common.js';

export default {
  getLocalPreselection: makeGetLocalPreselectionFromBreadcrumbs(
    '.breadcrumbs a, .breadcrumnb li, .breadcrumb a',
    { category: 'Category', brand: 'Manufacturer' },
    (...elements) =>
      elements
        .map((elem) => {
          const text = (elem.innerText || elem.textContent).trim();
          const ignore =
            elem.id === 'catCrumbHomeLink' || text === 'Home' || elem.getAttribute('data-cm-ignore');
          return !ignore && text;
        })
        .filter(Boolean),
  ),
};
