import { createSelector } from '@reduxjs/toolkit';
import _difference from 'lodash-es/difference.js';

import { isolatedDialog, isolatedHeader, isolatedVerifyFitment } from 'Addons/fitmentSearch/isolatedKeys.ts';
import { selectedVehicleSelector } from 'Core/reducers/fitmentSearch/storage.ts';
import { FacetValue, Vehicle } from 'Models/index.ts';
import { createFacetCollectionSelector, createIsolatedSelectorCreator } from 'Modules/selectors.js';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import requestConfig from 'Models/uiConfig/requestConfig.js';
import { equalBy } from 'Utils/array.js';
import { fitmentSearchSelector } from './fitmentSearch.js';

function createRootSelector(isolatedKey = null) {
  return isolatedKey ? (state) => fitmentSearchSelector(state)[isolatedKey] : fitmentSearchSelector;
}

const createFitmentSearchBaseFieldsSelector = createIsolatedSelectorCreator(
  createRootSelector,
  ([rootSelector]) =>
    (state) => {
      return rootSelector(state).fields.base;
    },
);

export const createFitmentSearchFieldsSelector = createIsolatedSelectorCreator(
  createRootSelector,
  ([rootSelector]) =>
    (state) => [...rootSelector(state).fields.base, ...rootSelector(state).fields.extra],
);

export const createFitmentSearchExtraFieldsSelector = createIsolatedSelectorCreator(
  createRootSelector,
  ([rootSelector]) =>
    (state) =>
      rootSelector(state).fields.extra,
);

const createIsolatedRequestSelector = createIsolatedSelectorCreator(
  createRootSelector,
  ([rootSelector]) =>
    (state) =>
      rootSelector(state).request,
);

export const createFitmentSearchRequestSelectionSelector = createIsolatedSelectorCreator(
  createIsolatedRequestSelector,
  ([requestSelector]) =>
    (state) =>
      requestSelector(state).selection,
);

const createIsolatedResponseSelector = createIsolatedSelectorCreator(
  createRootSelector,
  ([rootSelector]) =>
    (state) =>
      rootSelector(state).response,
);

export const createFitmentSearchResponseAllFacetsSelector = createIsolatedSelectorCreator(
  createIsolatedResponseSelector,
  (selectors) => createFacetCollectionSelector(selectors, (response) => response.facets),
);

export const createFilteredFitmentSearchResponseFieldsSelector = createIsolatedSelectorCreator(
  createFitmentSearchResponseAllFacetsSelector,
  createFitmentSearchBaseFieldsSelector,
  createFitmentSearchExtraFieldsSelector,
  createFitmentSearchFieldsSelector,
  (selectors, isolatedKey) =>
    createSelector(selectors, (facets, fitmentBaseFields, fitmentExtraFields, fitmentFields) => {
      const allBaseFieldsSelected = fitmentBaseFields.every((field) => {
        const facet = facets.get(field);
        return !facet || facet.selection?.length;
      });
      return facets
        .filter((facet) => {
          // remove fully universal facets
          if (facet.values.length === 1 && facet.values[0].term === 'Universal') {
            return false;
          }

          // do not remove any base field
          if (fitmentBaseFields.includes(facet.field)) {
            return true;
          }

          // do not remove any extra field if there is only one base field
          if (
            fitmentBaseFields.length === 1 &&
            fitmentExtraFields.length &&
            fitmentFields.includes(facet.field)
          ) {
            return true;
          }

          // remove the extra field in the following cases
          if (
            (!fitmentSearchConfig.isExtraFieldsAlwaysVisible &&
              (fitmentSearchConfig.isOnCategorySelectionPage ||
                isolatedKey === isolatedHeader ||
                (isolatedKey === isolatedDialog && !requestConfig.hasSearchResults))) ||
            !allBaseFieldsSelected ||
            !fitmentFields.includes(facet.field)
          ) {
            return false;
          }

          // do not remove selected extra fields
          if (facet.selection.length) {
            return true;
          }

          // do not remove extra fields if:
          // partial mode enabled
          // or in VerifyFitment widget
          // or if there is values and not only one Universal value
          return (
            fitmentSearchConfig.isVehicleWidgetInPartialMode ||
            isolatedKey === isolatedVerifyFitment ||
            (facet.values.length > 0 && !(facet.values.length === 1 && facet.values[0].term === 'Universal'))
          );
        })
        .map((facet) => facet.field);
    }),
);

export const createRequiredFitmentSearchFieldsSelector = createIsolatedSelectorCreator(
  createFilteredFitmentSearchResponseFieldsSelector,
  createFitmentSearchBaseFieldsSelector,
  (selectors, isolatedKey) =>
    createSelector(selectors, (fields, fitmentBaseFields) => {
      const requiredFields =
        isolatedKey === isolatedVerifyFitment
          ? fields
          : [fitmentBaseFields, fields].reduce((a, b) => a.filter((c) => b.includes(c)));
      return requiredFields.length ? requiredFields : null;
    }),
);

export const createFitmentSearchResponseFacetsSelector = createIsolatedSelectorCreator(
  createFitmentSearchResponseAllFacetsSelector,
  createFilteredFitmentSearchResponseFieldsSelector,
  (selectors) =>
    createFacetCollectionSelector(selectors, (facets, fields) =>
      facets.filter((f) => fields.includes(f.field)),
    ),
);

export const createFitmentSearchResponseSelectionSelector = createIsolatedSelectorCreator(
  createFitmentSearchResponseFacetsSelector,
  (selectors) => createSelector(selectors, (facets) => facets.selection),
);

export const createFitmentSearchSelectionChangedSelector = createIsolatedSelectorCreator(
  createFitmentSearchRequestSelectionSelector,
  createFitmentSearchResponseSelectionSelector,
  (selectors) =>
    createSelector(
      selectors,
      (requestSelection, responseSelection) =>
        !equalBy(requestSelection, responseSelection, FacetValue.termKey),
    ),
);

export const createAllBaseFieldsHaveSelectionSelector = createIsolatedSelectorCreator(
  createFitmentSearchBaseFieldsSelector,
  createFitmentSearchResponseSelectionSelector,
  ([baseFieldsSelector, selectionSelector]) =>
    (state) => {
      const baseFields = baseFieldsSelector(state);
      return !(
        baseFields &&
        _difference(
          baseFields,
          selectionSelector(state).map((sel) => sel.field),
        ).length
      );
    },
);

export const createAllRequiredFieldsHaveSelectionSelector = createIsolatedSelectorCreator(
  createRequiredFitmentSearchFieldsSelector,
  createFitmentSearchResponseSelectionSelector,
  ([requiredFieldsSelector, selectionSelector]) =>
    (state) => {
      const requiredFields = requiredFieldsSelector(state);
      return !(
        requiredFields &&
        _difference(
          requiredFields,
          selectionSelector(state).map((sel) => sel.field),
        ).length
      );
    },
);

export const createResponseVehicleSelector = createIsolatedSelectorCreator(
  createFitmentSearchResponseSelectionSelector,
  createFitmentSearchFieldsSelector,
  (selectors) =>
    createSelector(selectors, (selection, fitmentFields) => new Vehicle(selection, fitmentFields)),
);

export const createIsServerChangedVehicleSelector = createIsolatedSelectorCreator(
  createResponseVehicleSelector,
  createAllRequiredFieldsHaveSelectionSelector,
  ([responseVehicleSelector, isAllRequiredFieldHasSelectionSelector]) =>
    createSelector(
      selectedVehicleSelector,
      responseVehicleSelector,
      isAllRequiredFieldHasSelectionSelector,
      (selectedVehicle, responseVehicle, isAllRequiredFieldHasSelection) =>
        !(
          selectedVehicle.equals(responseVehicle) ||
          (selectedVehicle.isMoreSpecificThan(responseVehicle) && isAllRequiredFieldHasSelection)
        ),
    ),
);
