import { createReducer } from '@reduxjs/toolkit';

import {
  addToGarageRequested,
  ignoreInexactFields,
  forceVehicleSelectionLater,
  removalFromGarageRequested,
  replaceVehicleValue,
  setGarageRequested,
  vehicleDiscardingRequested,
  vehicleExtraFieldsDiscardingRequested,
  vehicleSelected,
  vehicleSpecificationNeeded,
} from 'Core/actions/fitmentSearch/index.js';
import { Vehicle, VehicleCollection } from 'Models/index.ts';
import { garageCleanupRequested } from 'Core/actions/fitmentSearch/vehicle.ts';
import { resetRequest } from 'Core/actions/request.js';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import { getForceVehicleSelectionLater, loadGarageData, loadSelectedVehicle } from 'Utils/userStorage.js';

import type { ReduxStateType } from 'Core/store.ts';
import type { VehicleValue } from 'Models/vehicle.ts';

const emptyState = {
  vehicles: {},
  garage: [],
  selectedVehicle: null,
  vehicleFromUriSelection: [],
  forceVehicleSelectionLater: null,
};
const initGarage = loadGarageData();
const initSelectedVehicle = loadSelectedVehicle();
const initState = fitmentSearchConfig.garageDetected
  ? {
      vehicles: Object.fromEntries(initGarage.map((vehicle) => [vehicle.key, vehicle])),
      garage: initGarage.map((vehicle) => vehicle.key),
      selectedVehicle: initGarage.includes(initSelectedVehicle) ? initSelectedVehicle.key : null,
      forceVehicleSelectionLater: getForceVehicleSelectionLater() ?? false,
    }
  : {
      vehicles: { [initSelectedVehicle.key]: initSelectedVehicle },
      garage: [],
      selectedVehicle: initSelectedVehicle.key,
      forceVehicleSelectionLater: getForceVehicleSelectionLater() ?? false,
    };

type Storage = typeof initState;

export default createReducer(initState, (builder) =>
  builder
    .addCase(vehicleSelected, (draft, { payload }) => {
      if (draft.selectedVehicle !== payload.key) {
        replaceSelectedVehicleAndAddToGarage(draft, () => payload);
      }
    })
    .addCase(vehicleDiscardingRequested, (draft) => {
      draft.selectedVehicle = null;
    })
    .addCase(
      vehicleExtraFieldsDiscardingRequested,
      (draft, { meta: { fitmentBaseFields, fitmentFields } }) => {
        // simplified version, ignores garage
        // it's used for only one store that has no garage (turbochargersdirect)
        const selectedVehicle = draft.vehicles[`${draft.selectedVehicle}`] || Vehicle.null;
        saveAndSelectNewVehicle(
          draft,
          new Vehicle(
            selectedVehicle.filter((v) => fitmentBaseFields.includes(v.field)),
            fitmentFields,
          ),
        );
      },
    )
    .addCase(vehicleSpecificationNeeded, (draft, { payload: fieldsToSpecify }) => {
      replaceSelectedVehicleAndAddToGarage(draft, (vehicle) =>
        vehicle.concat(
          fieldsToSpecify
            .filter((field) => !vehicle.some((v) => v.field === field))
            .map((field) => ({ field, term: '__inexact', value: '__inexact' }) as VehicleValue),
        ),
      );
    })
    .addCase(ignoreInexactFields, (draft) => {
      replaceSelectedVehicleAndAddToGarage(draft, (vehicle) =>
        vehicle.mapToVehicle((v) => ({ ...v, term: ignoreInexact(v.term), value: ignoreInexact(v.value) })),
      );
    })
    .addCase(garageCleanupRequested, () => emptyState)
    .addCase(setGarageRequested, (state, { payload: { vehicles, selectVehicle } }) => ({
      ...state,
      vehicles: Object.fromEntries(vehicles.map((v) => [v.key, v])),
      selectedVehicle: selectVehicle ? vehicles.last.key : state.selectedVehicle,
      garage: vehicles.map((v) => v.key),
    }))
    .addCase(removalFromGarageRequested, (state, { payload }) => ({
      ...state,
      selectedVehicle: state.selectedVehicle === payload.key ? null : state.selectedVehicle,
      garage: state.garage.filter((key) => key !== payload.key),
    }))
    .addCase(addToGarageRequested, (draft, { payload }) => {
      const { garage, vehicles } = draft;

      if (!garage.includes(payload.key)) {
        garage.push(payload.key);
        vehicles[payload.key] = payload;
      }
    })
    .addCase(replaceVehicleValue, (draft, { meta: { isolatedKey } }) => {
      if (!isolatedKey) {
        draft.selectedVehicle = null;
      }
    })
    .addCase(forceVehicleSelectionLater, (draft) => {
      draft.forceVehicleSelectionLater = true;
    })
    .addMatcher(
      ({ type }) => type === resetRequest().type,
      (draft, { discardUserPreselection }) => {
        if (discardUserPreselection) {
          draft.selectedVehicle = null;
        }
      },
    ),
);

function ignoreInexact(v: string) {
  return v === '__inexact' ? '__ignored' : v;
}

const fitmentSearchSelector = (state: ReduxStateType) => state.fitmentSearch;

export function selectedVehicleSelector(state: ReduxStateType) {
  const { selectedVehicle, vehicles } = fitmentSearchSelector(state).storage;
  return selectedVehicle ? vehicles[selectedVehicle] : Vehicle.null;
}

export function isVehicleSelectedSelector(state: ReduxStateType) {
  return selectedVehicleSelector(state).notNull;
}

export function vehicleInexactSelector(state: ReduxStateType) {
  return selectedVehicleSelector(state).some((v) => v.term === '__inexact' || v.value === '__inexact');
}

export function vehicleStronglyInexactSelector(state: ReduxStateType) {
  return selectedVehicleSelector(state).some((v) => !Vehicle.isUsualTerm(v.term) || v.term === 'Any');
}

export function garageDataSelector(state: ReduxStateType) {
  const { vehicles, garage } = fitmentSearchSelector(state).storage;
  return garage.length ? new VehicleCollection(garage.map((id) => vehicles[id])) : VehicleCollection.empty;
}

export function forceVehicleSelectionLaterSelector(state: ReduxStateType) {
  return fitmentSearchSelector(state).storage.forceVehicleSelectionLater;
}

function replaceSelectedVehicleAndAddToGarage(
  draft: Storage,
  transform: (selectedVehicle: Vehicle) => Vehicle,
) {
  const newVehicle = transform(draft.vehicles[`${draft.selectedVehicle}`] || Vehicle.null);
  const { vehicles, garage, selectedVehicle } = draft;

  if (!fitmentSearchConfig.garageDetected) {
    if (!selectedVehicle || !vehicles[selectedVehicle].isMoreSpecificThan(newVehicle)) {
      saveAndSelectNewVehicle(
        draft,
        (selectedVehicle && vehicles[selectedVehicle].merge(newVehicle)) || newVehicle,
      );
    }
    return;
  }

  const sameVehicleIndex = garage.findIndex((key) => vehicles[key].equals(newVehicle));
  if (sameVehicleIndex >= 0) {
    draft.selectedVehicle = garage[sameVehicleIndex];
    return;
  }

  const moreSpecificVehicleKey = garage.find((key) => vehicles[key].isMoreSpecificThan(newVehicle));
  if (moreSpecificVehicleKey) {
    draft.selectedVehicle = moreSpecificVehicleKey;
    return;
  }

  const lessSpecificVehicleIndex = garage.findIndex((key) => newVehicle.isMoreSpecificThan(vehicles[key]));
  if (lessSpecificVehicleIndex >= 0) {
    draft.garage[lessSpecificVehicleIndex] = newVehicle.key;
    saveAndSelectNewVehicle(draft, newVehicle);
    return;
  }

  const { vehicle: mergedVehicle, index: compatibleVehicleIndex } = garage.reduce(
    (res, key, index) => (res.vehicle ? res : { vehicle: vehicles[key].merge(newVehicle), index }),
    { vehicle: null as Vehicle | null, index: -1 },
  );
  if (mergedVehicle) {
    draft.garage[compatibleVehicleIndex] = mergedVehicle.key;
    saveAndSelectNewVehicle(draft, mergedVehicle);
    return;
  }

  garage.push(newVehicle.key);
  saveAndSelectNewVehicle(draft, newVehicle);
}

function saveAndSelectNewVehicle(draft: Storage, newVehicle: Vehicle) {
  draft.vehicles[newVehicle.key] = newVehicle;
  draft.selectedVehicle = newVehicle.key;
}
