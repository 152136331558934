import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createSelectRepeater, SelectContextProvider } from 'Components/smallComponents/select.tsx';
import { replaceVehicleValue, vehicleSelected } from 'Core/actions/fitmentSearch/index.js';
import {
  createFitmentSearchResponseFacetsSelector,
  createFitmentSearchResponseSelectionSelector,
  createAllRequiredFieldsHaveSelectionSelector,
  fitmentSearchFieldsSelector,
  selectedVehicleSelector,
  vehicleStronglyInexactSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import { cloneSafe } from 'Utils/components.ts';
import { createFacetCollectionSelector } from 'Modules/selectors.js';
import { FacetValue, Vehicle } from 'Models/index.ts';

export default function VehicleSpecifier({
  template,
  appendedClasses,
  rootRef,
  isolatedKey,
  onSelectVehicle,
}) {
  const dispatch = useDispatch();

  const selection = useSelector(createFitmentSearchResponseSelectionSelector(isolatedKey));
  const isAllRequiredFieldHasSelection = useSelector(
    createAllRequiredFieldsHaveSelectionSelector(isolatedKey),
  );
  const facets = useFacetsToSpecify(isolatedKey);
  const vehicleString = useSelector((state) => selectedVehicleSelector(state).toString());
  const fitmentFields = useSelector(fitmentSearchFieldsSelector);

  const selects = createSelectRepeater(
    facets.map(({ field, name, facetedValues }) =>
      facetedValues.length
        ? {
            entries: facetedValues.map((value) => ({
              value: value.value,
              term: value.term,
              selected: FacetValue.isInCollection(value, selection),
            })),
            extraClassName: 'cm_vehicle-widget_select',
            title: name,
            hideNullOption: true,
            key: field,
            onChange: (term) =>
              dispatch(
                replaceVehicleValue(
                  facetedValues.find((v) => v.term === term),
                  isolatedKey,
                  fitmentFields,
                ),
              ),
          }
        : null,
    ),
  );

  const selectVehicle = () => {
    dispatch(vehicleSelected(new Vehicle(selection, fitmentFields), { isolatedKey }));
    onSelectVehicle();
  };

  const component = template.call({
    allowToSetVehicle: isAllRequiredFieldHasSelection,
    vehicleString,
    selects,
    selectFields: facets.map((f) => f.field),
    selectVehicle,
    template: 'inexact',
  });

  return <SelectContextProvider>{cloneSafe(component, rootRef, { appendedClasses })}</SelectContextProvider>;
}

const noFields = [];
const fieldsToSpecifySelector = (state) =>
  vehicleStronglyInexactSelector(state)
    ? selectedVehicleSelector(state)
        .filter((v) => !Vehicle.isUsualTerm(v.term) || v.term === 'Any')
        .map((v) => v.field)
    : noFields;

export function useFacetsToSpecify(isolatedKey) {
  return useSelector(
    useMemo(
      () =>
        createFacetCollectionSelector(
          createFitmentSearchResponseFacetsSelector(isolatedKey),
          fieldsToSpecifySelector,
          (facets, fieldsToSpecify) => {
            return facets.filter((facet) => fieldsToSpecify.includes(facet.field) && facet.values.length);
          },
        ),
      [isolatedKey],
    ),
  );
}
