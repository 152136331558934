import { createSelector } from '@reduxjs/toolkit';

import { dialogName as searchBoxDialogName } from 'Components/dialog/searchBoxDialog.tsx';
import {
  setAutocompleteResponse,
  setDefaultAutocompleteSuggestions,
  sendAutocompleteRequest,
} from 'Core/actions/autocomplete.ts';
import { defaultAutocompleteResponseState } from 'Core/reducers/autocomplete/response';
import {
  autocompleteDefaultSuggetionsSelector,
  autocompleteResponseItemsSelector,
  autocompleteRequestQuerySelector,
  autocompleteVisibilitySelector,
} from 'Core/selectors/autocomplete.ts';
import { createDialogOpenedSelector } from 'Core/selectors/dialog.js';
import { autocompletePreselectionSelector } from 'Core/selectors/preselection.js';
import { AutocompleteItem, FacetValueBase } from 'Models/index.ts';
import requestConfig from 'Models/uiConfig/requestConfig.js';
import { autocomplete, abortControllerCreator } from 'Modules/serverApi/index.js';

import type { EpicArg } from 'Core/middlewares/epics.ts';
import type { AutocompleteItemType } from 'Models/autocompleteItem.ts';
import type { ServerModel } from 'Modules/serverApi/types.ts';

const types: AutocompleteItemType[] = requestConfig.autocomplete.order;

const createAbortController = abortControllerCreator();

const isRedirectItem = (item: ServerModel.AutocompleteItem): item is ServerModel.AutocompleteProductItem =>
  item.Type === 'Product';

const requestSelector = createSelector(
  autocompleteRequestQuerySelector,
  autocompletePreselectionSelector,
  (query: string, selection: FacetValueBase[]) => ({ query, selection }),
);

export default async function ({ dispatch, state, stateChangedBy, action: { payload, type } }: EpicArg) {
  const isInDialog = createDialogOpenedSelector(searchBoxDialogName);
  const isRequestChanged = stateChangedBy(requestSelector);
  const isVisibilityChanged = stateChangedBy(autocompleteVisibilitySelector);
  const isAutocompleteVisible = autocompleteVisibilitySelector(state);

  const isActionTrigged = type === sendAutocompleteRequest.type;

  if (
    ((isInDialog && !isRequestChanged) ||
      (!isInDialog && (!isAutocompleteVisible || (!isRequestChanged && !isVisibilityChanged)))) &&
    !isActionTrigged
  ) {
    return;
  }

  const request = isActionTrigged ? payload : requestSelector(state);

  const isDefaultSuggestionsRequest = !request.query;
  const defaultSuggestion = autocompleteDefaultSuggetionsSelector(state);
  const allowToDefaultSuggestionRequest = isDefaultSuggestionsRequest && !defaultSuggestion;

  const selection = allowToDefaultSuggestionRequest ? { query: request.query } : request;

  if (allowToDefaultSuggestionRequest || request.query.length >= 3) {
    const autocompleteResponseItems = autocompleteResponseItemsSelector(state) as AutocompleteItem[];
    const isFilterWithinAutocomplete = isActionTrigged && !!request.query;

    try {
      const rawResponse = (await autocomplete(
        { ...selection, types },
        createAbortController(),
      )) as ServerModel.AutocompleteResponse;

      const responseItems = rawResponse.map((item) => new AutocompleteItem(item));

      if (isDefaultSuggestionsRequest) {
        dispatch(setDefaultAutocompleteSuggestions(responseItems));
      } else {
        // if performed filter within autocomplete, then update only product, do not update text or catagory suggestions
        // TODO: send 'type' in request to get only product, we don't need other types
        const items = isFilterWithinAutocomplete
          ? autocompleteResponseItems
              .filter((i) => i.type !== 'product')
              .concat(responseItems?.filter((i) => i.type === 'product') ?? [])
          : responseItems;

        const { TotalHits: totalHits = 0 } = rawResponse.find(isRedirectItem) || {};

        dispatch(setAutocompleteResponse({ items, totalHits, query: request.query }));
      }
    } catch (err) {
      dispatch(setAutocompleteResponse(defaultAutocompleteResponseState));
    }
  }
}
