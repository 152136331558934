import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getKeyFromWidgetName } from 'Addons/fitmentSearch/isolatedKeys.ts';
import { dialogOpened } from 'Core/actions/dialog.ts';
import { useResizeState } from 'Core/hooks/index.js';
import {
  createFitmentSearchResponseFacetsSelector,
  createFitmentSearchResponseSelectionSelector,
  isNoVehicleSpecificResultsSelector,
  isVehicleSelectedSelector,
  isVehicleSelectionForcedSelector,
  selectedVehicleSelector,
  createIsServerChangedVehicleSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import { verifyFitmentProductDataSelector } from 'Core/selectors/product.ts';
import layoutType from './layoutType.ts';
import VehicleLabel from './vehicleLabel.js';
import VehicleDropdowns from './vehicleDropdowns.tsx';
import VehicleSpecifier, { useFacetsToSpecify } from './vehicleSpecifier.jsx';

const estimatedWidthPerSelect = 150;

const rowClass = ['cm_row', 'cm_vehicle-widget__row'];
const columnClass = ['cm_column', 'cm_vehicle-widget__column'];
const hiddenClass = 'cm_hide';

const templateToComponentMap = {
  active: VehicleDropdowns,
  locked: VehicleLabel,
  inexact: VehicleSpecifier,
  universal: VehicleLabel,
  unknown: VehicleLabel,
  custom: VehicleLabel,
};

const layoutStateToClassMap = {
  [layoutType.row]: rowClass,
  [layoutType.column]: columnClass,
};

export default function VehicleWidget({
  isAlwaysActive,
  isAlwaysColumnLayout = false,
  columnBreakpoint,
  name,
  ...otherProps
}) {
  const rootRef = useRef();
  const dispatch = useDispatch();

  const selectedVehicle = useSelector(selectedVehicleSelector);
  const isVehicleSelectionForced = useSelector(isVehicleSelectionForcedSelector);

  const [aboutToChange, setAboutToChange] = useState(false);

  const isolatedKey = getKeyFromWidgetName(name) ?? otherProps.isolatedKey;

  const isHidden = useIsHidden(name, isolatedKey, otherProps.isHidden);
  const layoutState = useLayoutState(
    rootRef,
    isolatedKey,
    columnBreakpoint,
    isAlwaysColumnLayout,
    otherProps.fields,
  );
  const templateName = useTemplateName(isAlwaysActive || aboutToChange, name, isolatedKey);

  useEffect(
    function showLabelWhenVehicleChanges() {
      setAboutToChange(false);
    },
    [selectedVehicle],
  );

  const onChangeVehicle = () => setAboutToChange(true);
  const onSelectVehicle = () => {
    otherProps.onSelectVehicle?.();
    setAboutToChange(false);
  };
  const openDialog = () => dispatch(dialogOpened('vehicle-dialog', { withCurrentVehicle: true }));

  const Component = templateToComponentMap[templateName];
  const props = {
    ...otherProps,
    name,
    isolatedKey,
    aboutToChange,
    onChangeVehicle,
    onSelectVehicle,
    openDialog,
    rootRef,
    layoutState,
    isVehicleSelectionForced,
    appendedClasses: [...(layoutStateToClassMap[layoutState] || ''), isHidden ? hiddenClass : ''],
    templateName,
  };
  return <Component {...props} />;
}

function useIsHidden(name, isolatedKey, isHidden) {
  const [nameRoot] = name.split('_');
  return useSelector((state) => {
    const verifyFitmentProductData = verifyFitmentProductDataSelector(state);
    const isNoVehicleSpecificResults = isNoVehicleSpecificResultsSelector(state);
    const isAnyFitmentFacetSelected =
      !!createFitmentSearchResponseSelectionSelector(isolatedKey)(state)?.length;

    return (
      isHidden ||
      (nameRoot === 'VerifyFitment' &&
        (!verifyFitmentProductData || !Object.keys(verifyFitmentProductData).length)) ||
      (nameRoot !== 'HeaderVehicleWidget' &&
        nameRoot !== 'DialogVehicleWidget' &&
        nameRoot !== 'TabsVehicleWidget' &&
        nameRoot !== 'GarageVehicleWidget' &&
        isNoVehicleSpecificResults &&
        !isAnyFitmentFacetSelected)
    );
  });
}

function useLayoutState(rootRef, isolatedKey, columnBreakpoint, isAlwaysColumnLayout, fields) {
  const dropdownMaxColumnWidth = useSelector(
    columnBreakpoint
      ? () => columnBreakpoint
      : (state) => {
          const responseFacets = createFitmentSearchResponseFacetsSelector(isolatedKey)(state);
          const selectCount = (
            fields ? responseFacets.filter((facet) => fields.includes(facet.field)) : responseFacets
          ).length;
          return selectCount * estimatedWidthPerSelect;
        },
  );
  return useResizeState(
    useCallback(
      (state = isAlwaysColumnLayout ? layoutType.column : layoutType.row) =>
        isAlwaysColumnLayout
          ? layoutType.column
          : rootRef.current?.offsetParent
            ? rootRef.current.clientWidth > dropdownMaxColumnWidth
              ? layoutType.row
              : layoutType.column
            : state,
      [dropdownMaxColumnWidth, isAlwaysColumnLayout, rootRef],
    ),
  );
}

function useTemplateName(forceDropdowns, name, isolatedKey) {
  const facetsToSpecify = useFacetsToSpecify(isolatedKey);
  const [nameRoot] = name.split('_');

  return useSelector(
    forceDropdowns
      ? () => 'active'
      : (state) => {
          if (nameRoot === 'VerifyFitment') {
            const fitsTheVehicle = verifyFitmentProductDataSelector(state)?.fitsTheVehicle;

            if (fitsTheVehicle === 'universal') {
              return 'universal';
            }

            if (fitsTheVehicle === 'unknown') {
              return 'unknown';
            }

            if (facetsToSpecify.length) {
              return 'inexact';
            }
          }

          return !isVehicleSelectedSelector(state) || createIsServerChangedVehicleSelector(isolatedKey)(state)
            ? 'active'
            : 'locked';
        },
  );
}
