import { useDispatch } from 'react-redux';

import { setNextPage, setPageNumber, setPrevPage } from 'Core/actions/request.js';
import { cloneSafe } from 'Utils/components.ts';
const typeToActionMap = {
  next: setNextPage,
  prev: setPrevPage,
  page: setPageNumber,
  delimiter: null,
};

export default function PaginationButton({
  template,
  type,
  pageNumber,
  totalPages,
  isActive,
  searchParams,
  style,
  hideDropdown,
}) {
  const dispatch = useDispatch();
  const actionCreator = typeToActionMap[type];

  const searchParamsLink = searchParams
    ? `${searchParams}&pageNumber=${pageNumber + 1}`
    : `?pageNumber=${pageNumber + 1}`;

  let props = { style };
  let component;
  if (actionCreator) {
    const onClick = (e) => {
      e.preventDefault();
      if (!isActive) {
        if (hideDropdown) {
          hideDropdown();
        }
        dispatch(actionCreator(pageNumber));
      }
    };
    component = template.call({
      template: type,
      page: pageNumber + 1,
      totalPages,
      isActive,
      searchParamsLink,
    });
    props = { ...props, onClick };
  } else {
    component = template.call({ template: type });
  }

  return cloneSafe(component, null, { ...props });
}
