import { useSelector } from 'react-redux';

import Table from 'Components/smallComponents/table.tsx';
import { fitmentTableResponse } from 'Core/selectors/fitmentSearch/index.js';
import { FitmentField } from 'Modules/serverApi/types.ts';

import type { FC } from 'react';
import type { Params as TableParams } from 'Components/smallComponents/table.tsx';
import type { TemplateFunction } from 'Components/types.ts';
import type { ServerModel } from 'Modules/serverApi/types.ts';

type FitmentFieldType = {
  vehicleSpecific: boolean;
  noFitmentData: boolean;
  universalFit: boolean;
  manyFitments: boolean;
};

interface Props {
  template: TemplateFunction<TableParams | FitmentFieldType>;
  columnBreakpoint?: number;
}

const FitmentTable: FC<Props> = ({ template, columnBreakpoint }) => {
  const response = useSelector(fitmentTableResponse) as ServerModel.FitmentsResponseType;

  if (
    !response ||
    (response.Fitment === FitmentField.vehicleSpecific &&
      (!response.Fitments?.length || !response.Fields?.length))
  ) {
    return null;
  }

  const { Fields, Fitment, Fitments } = response;

  const fitmentFieldData = {
    vehicleSpecific: Fitment === FitmentField.vehicleSpecific,
    noFitmentData: Fitment === FitmentField.noFitmentData,
    universalFit: Fitment === FitmentField.universalFit,
    manyFitments: Fitment === FitmentField.manyFitments,
  };

  if (!Fields?.length || !Fitments?.length) {
    return template.call({ ...fitmentFieldData });
  }

  const props = {
    template,
    data: Fitments,
    extraProps: { ...fitmentFieldData },
    fields: Fields,
    columnBreakpoint,
  };

  return <Table {...props} />;
};

export default FitmentTable;
