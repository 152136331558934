const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = ['Submodel'];
const ignoreFields = ['Universal'];

globalThis.Convermax.quickview = (item) => (e) => {
  e.preventDefault();
  window.quickview(`/product.asp?lt_c=1&itemid=${item.CatalogID}&qv=1`);
};

export default {
  platform: 'shift4shop',
  searchPageUrl: '/search',
  SearchRequestDefaults: {
    pageSize: 36,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
  },
  facets: {
    rangedFacet: { fields: ['price'], name: 'priceFacet' },
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: {
        insertAfter:
          'body.category-page .category-products.clearfix .product-items.product-items-3, body.category-page .subcategories_block',
      },
      template: 'SearchPage',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'Name': 'A to Z',
        'Name:desc': 'Z to A',
        'Price': 'Price: Ascending',
        'Price:desc': 'Price: Descending',
      },
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    { name: 'SearchResult' },
    {
      name: 'SearchBoxDesktopTablet',
      type: 'SearchBoxDialogButton',
      location: { replace: 'section.top-nav #FRAME_SEARCH', class: 'searchBox cm_mobile-hide' },
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchBoxDialogButton',
      location: { firstChildOf: 'section.top-nav .search-form-col', class: 'searchBox cm_desktop-hide' },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: { insertAfter: 'body.home-page section.navbar-wrapper.hidden-xs', class: 'container' },
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'Garage',
      location: {
        lastChildOf: 'section.top-nav .useraccount',
        class: 'cm_garage__container cm_garage__desktop',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'GarageMobile',
      type: 'Garage',
      location: {
        lastChildOf: '#FRAME_MENU +  .col-sm-4.search-form-col',
        class: 'cm_garage__container cm_garage__mobile',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { insertBefore: 'body.listing-page .addToCartBlock' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: 'body.listing-page #rTabs a[href="#tab-fitment"]',
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTableTabMobile',
      type: 'FitmentTable',
      location: 'body.listing-page #rTabs .r-tabs-accordion-title a[href="#tab-fitment"]',
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTable',
      location: 'body.listing-page #rTabs #tab-fitment',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
