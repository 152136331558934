import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { toggle } from 'Core/actions/request.js';
import { FacetValue, Step } from 'Models/index.ts';
import { cloneSafe } from 'Utils/components.ts';
import { getBoundsFromFacet, getMinMaxFromTerm, decimalToImperial } from 'Utils/ranged.ts';
import { simpleHandler } from 'Utils/roleHandler.js';
import { Slider, RangedInputs } from '../common/index.ts';

import type { FC, MouseEventHandler } from 'react';
import type { TemplateFunction, TemplateFunctionInvoker, TemplateResult } from 'Components/types.ts';
import type { MinMax } from 'Utils/ranged.ts';
import type { FacetCommonProps, CommonParams } from '../baseFacet.js';
import type { Params as RangedInputsParams } from '../common/rangedInputs.js';

type Params = Omit<CommonParams, 'selection'> & {
  slider: JSX.Element;
  min: number;
  max: number;
  isNullRange: boolean;
  selectedRange: MinMax;
  Inputs: TemplateFunctionInvoker<RangedInputsParams>;
};

type Props = Omit<FacetCommonProps, 'templateFunc'> & {
  templateFunc: TemplateFunction<Params>;
  allowEqualBounds?: true;
};

const RangedSliderFacet: FC<Props> = ({
  facet,
  field,
  allowEqualBounds,
  templateFunc,
  facetRef,
  commonParams,
  commonRoles,
  config: { step: rawStep = '1', slider: { mode = '' } = {} },
}) => {
  const dispatch = useDispatch();
  const step = useMemo(() => new Step(rawStep), [rawStep]);

  const bounds: MinMax = getBoundsFromFacet(facet, step);
  if (bounds[0] === bounds[1] && !allowEqualBounds) {
    return null;
  }

  const selectedValues: MinMax = facet.selection[0] ? getMinMaxFromTerm(facet.selection[0].term) : ['*', '*'];
  const [min, max] = bounds.map((m) => +m);

  const selectedRange = selectedValues.map((selectedValue, i) => {
    const value = selectedValue.replace(/^\*$/, String([min, max][i]));
    return step.imperial ? decimalToImperial(value) : value;
  }) as MinMax;

  function normRangedSelection(values: MinMax, [min, max]: MinMax): MinMax {
    return values.map((value, i) => {
      if (value === '*') {
        return [min, max][i];
      }
      if (+value > +max) {
        return max;
      }
      return +value < +min ? min : value;
    }) as MinMax;
  }
  const slider = (
    <Slider
      field={field}
      bounds={bounds}
      selected={normRangedSelection(selectedValues, bounds)}
      step={step}
      mode={mode}
      key={`slider-for-${field}`}
    />
  );

  function handleCustomRange([minValue, maxValue]: MinMax) {
    const term = FacetValue.createRangedTerm(minValue, maxValue);
    const isSelected = !(minValue === '*' && maxValue === '*');
    dispatch(toggle({ term, field, isUnique: true, isSelected }, { mayDiscardValue: true }));
  }
  const Inputs = (templ: TemplateFunction<RangedInputsParams>) =>
    (
      <RangedInputs
        template={templ}
        handleCustomRange={handleCustomRange}
        key={`inputs-for-${field}`}
        bounds={bounds}
        step={step}
        mode={mode}
        selectedValues={selectedValues}
      />
    ) as TemplateResult;

  const onClick: MouseEventHandler<HTMLElement> = simpleHandler({ ...commonRoles });

  const component = templateFunc.call({
    ...commonParams,
    slider,
    min,
    max,
    isNullRange: min === max,
    selectedRange,
    Inputs,
  });
  return cloneSafe(component, facetRef, { onClick });
};

export default RangedSliderFacet;
