import _uniq from 'lodash-es/uniq.js';

import { getKeyFromWidgetName, isolatedHeader } from 'Addons/fitmentSearch/isolatedKeys.ts';
import { mainConfig } from 'Models/uiConfig/componentlessWidgetsConfig.ts';
import defaultFitmentSearchConfig from 'Stores/_default-store/fitmentSearchConfig.js';
import requestConfig from './requestConfig.js';

const {
  fitmentSearch: {
    baseFields = [],
    categorySelectionPageRedirect,
    categorySelectionPages,
    doNotSaveSelectedVehicle,
    extraFields = [],
    expectResponseRedirect,
    isExtraFieldsAlwaysVisible = false,
    isVehicleSelectionIsolated = false,
    forceVehicleSelection = false,
    forceVehicleSelectionInterval = 60, // one hour
    getFitmentSearchTitle = () => 'Parts',
    isAutoRedirectDisabled,
    isAutoVehicleSelectionDisabled,
    isVehicleVisibleInUrl,
    onVehicleDiscarded: customOnVehicleDiscarded,
    onVehicleSelected: customOnVehicleSelected,
    onVerifyFitmentInitResponseReceived = () => {
      /* stub */
    },
    onVerifyFitmentResponseReceived = () => {
      /* stub */
    },
    vehicleDataExpires,
  } = {},
  Widgets,
} = mainConfig;

const fitmentFields = [...baseFields, ...extraFields];

const categorySelectionPage = Array.isArray(categorySelectionPages)
  ? categorySelectionPages[0]
  : categorySelectionPages;
const { pathname: categorySelectionPageUrl, field: categorySelectionPageField } = categorySelectionPage || {};

const trimEndSlash = (str) => (str?.endsWith('/') ? str.slice(0, -1) : str);

const currentCategorySelectionPageField =
  (categorySelectionPages &&
    []
      .concat(categorySelectionPages)
      .find((page) => trimEndSlash(window.location.pathname) === trimEndSlash(page.pathname))?.field) ||
  null;

const isFitmentSearchWidgetsVisible = Widgets.some((w) => {
  const [nameRoot] = w.name.split('_');
  return (
    (!requestConfig.hasSearchResults && w.type === 'FacetTiles' && w.visible && !w.isVehicleWidgetDisabled) ||
    ((w.type === 'VehicleTabs' || w.type === 'VehicleWidget' || w.type === 'VehicleTiles') &&
      nameRoot !== 'DialogVehicleWidget' && // dialog is always visible
      w.visible)
  );
});

const defaults = defaultFitmentSearchConfig || {
  onVehicleSelected: null,
  onVehicleDiscarded: null,
};

const onVehicleSelected = (vehicle) => {
  defaults.onVehicleSelected?.(vehicle);
  customOnVehicleSelected?.(vehicle);
  window.Convermax.onVehicleSelected?.(vehicle);
};

const onVehicleDiscarded = () => {
  defaults.onVehicleDiscarded?.();
  customOnVehicleDiscarded?.();
};

const fitmentSearchConfig = {
  baseFields,
  categorySelectionPageField,
  categorySelectionPageUrl,
  currentCategorySelectionPageField,
  doNotSaveSelectedVehicle,
  expectResponseRedirect,
  extraFields,
  fields: fitmentFields,
  isCategorySelectionPageRedirectEnabled: categorySelectionPageRedirect,
  isExtraFieldsAlwaysVisible,
  isOnCategorySelectionPage: !!currentCategorySelectionPageField,
  isVehicleSelectionIsolated,
  fitmentSearchTitle: getFitmentSearchTitle(),
  forceVehicleSelection,
  forceVehicleSelectionInterval,
  garageDetected: Widgets.some((w) => w.type === 'Garage'),
  isAutoRedirectDisabled,
  isAutoVehicleSelectionDisabled,
  isFitmentSearchWidgetsVisible,
  isolatedKeys: _uniq(
    [
      ...Widgets.map((w) => w.visible && getKeyFromWidgetName(w.name)),
      // if not FacetTiles widget on the search results pages then treat as isolated vehicle widget
      !requestConfig.hasSearchResults &&
        currentCategorySelectionPageField &&
        isFitmentSearchWidgetsVisible &&
        isolatedHeader,
    ].filter(Boolean),
  ),
  isVehicleVisibleInUrl,
  isVehicleWidgetInPartialMode: Widgets.some(
    (w) => (w.type === 'VehicleWidget' && w.fields) || w.type === 'VehicleTabs',
  ),
  onVehicleDiscarded,
  onVehicleSelected,
  onVerifyFitmentInitResponseReceived,
  onVerifyFitmentResponseReceived,
  vehicleDataExpires,
};

window.Convermax.templates.config.fitmentSearchConfig = fitmentSearchConfig;
export default fitmentSearchConfig;
