import pageType from 'Addons/search/pageType.ts';
import { mainConfig } from 'Models/uiConfig/componentlessWidgetsConfig.ts';
import defaultProductConfig from 'Platforms/shift4shop/productConfig.js';
import uiConfig from './uiConfig.js';

const { product: { getItemId: getItemIdCustom, priceFormatter: priceFormatterCustom, ...config } = {} } =
  mainConfig;

const requiredFields = ['id', 'image', 'title', 'url'];

const { getItemId: getItemIdDefault, ...defaults } = defaultProductConfig || {
  fields: requiredFields.reduce((fields, name) => {
    const errorMessage = `${name} field is missing in productConfig`;
    fields[name] = { toString: () => console.error(errorMessage) };
    return fields;
  }, {}),
  getItemId: () => '',
};

const fields = {
  key: '_docid',
  ...(defaults.fields || {}),
  ...(config.fields || {}),
};

const priceFormatter =
  priceFormatterCustom || new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const productConfig = {
  localItemId: uiConfig.pageType === pageType.product ? (getItemIdCustom || getItemIdDefault)() : '',
  // create new function instead of passing priceFormatter.format itself
  // to prevent bugs if format() would depend on this
  formatPrice: (price) => (price ? priceFormatter.format(price) : ''),
  noImageSrc: 'https://client.convermax.com/static/img/noimage.png',
  ...defaults,
  ...config,
  fields,
};

window.Convermax.templates.config.productConfig = productConfig;
export default productConfig;
