import pageType from 'Addons/search/pageType.ts';

export function makeGetLocalPreselectionFromBreadcrumbs(
  breadcrumbsSelector,
  pageTypeToFieldMap,
  parseBreadcrumbs,
) {
  const getTermFromBreadcrumbs = () =>
    parseBreadcrumbs(...window.document.querySelectorAll(breadcrumbsSelector)).join('>');
  const getter = (currentPageType) => {
    const field = pageTypeToFieldMap[currentPageType];
    const termFromBreadcrumbs = getTermFromBreadcrumbs();
    if (field && termFromBreadcrumbs) {
      const treeLevel =
        currentPageType === pageType.category ? termFromBreadcrumbs.split('>').length - 1 : null;
      return [{ field: pageTypeToFieldMap[currentPageType], term: termFromBreadcrumbs, treeLevel }];
    }
  };
  getter.defaults = {
    getter,
    get termFromBreadcrumbs() {
      return getTermFromBreadcrumbs();
    },
  };
  return getter;
}
