import _ceil from 'lodash-es/ceil.js';
import _floor from 'lodash-es/floor.js';

import type { Step, Facet } from 'Models/index.ts';

export type MinMax = [string, string];

export function getMinMaxFromTerm(term: string): MinMax {
  const termParts = term.split(' ');

  // `${value}`
  if (termParts.length === 1) {
    return [termParts[0], termParts[0]];
  }

  // `${min} TO ${max}`
  if (termParts.length === 3) {
    return [termParts[0], termParts[2]];
  }

  return ['*', '*'];
}

export function getBoundsFromFacet(facet: Facet, step: Step): MinMax {
  const ranges = facet.values
    .flatMap((v) => getMinMaxFromTerm(v.term))
    .filter((v) => v !== '*')
    .map(Number);

  const [min, max] = [
    _ceil(Math.min(...ranges), step.accuracy).toString(),
    _floor(Math.max(...ranges), step.accuracy).toString(),
  ];

  const resultBounds = [
    min === '*' ? '*' : step.fit(+min, 'ceil'),
    max === '*' ? '*' : step.fit(+max, 'floor'),
  ] as MinMax;

  return +resultBounds[0] >= +resultBounds[1] ? [resultBounds[0], resultBounds[0]] : resultBounds;
}

export function rangeFiniteness(range: MinMax): { finite: boolean; toPosInf: boolean; toNegInf: boolean } {
  const openMin = range[0] === '*';
  const openMax = range[1] === '*';

  return {
    finite: !openMin && !openMax,
    toPosInf: !openMin && openMax,
    toNegInf: openMin && !openMax,
  };
}

export function decimalToImperial(value: number | string): string {
  const numValue = +value;
  const foot = Math.floor(numValue);
  const inch = Math.round((numValue - foot) * 12);
  return foot ? `${foot}'${inch}"` : `${inch}"`;
}

export function getNumPercent(value: number, [min, max]: MinMax): number {
  return (100 / (+max - +min)) * (+value - +min);
}
