/* eslint-disable
   @typescript-eslint/no-namespace,
   no-use-before-define
 */
export declare namespace ServerModel {
  interface Action {
    Type: 'Redirect';
    RedirectUrl: string;
  }

  interface AutocompletePopularSearchItem {
    Type: 'PopularSearch';
    Text: string;
    TextHighlight: string;
    SessionCount: number;
  }

  interface AutocompleteFreetextItem {
    Type: 'Freetext';
    Text: string;
    TextHighlight: string;
    HitCount: number;
  }

  interface AutocompleteProductItem extends SearchItem {
    Type: 'Product';
    SuggestionGroup: string;
    TotalHits: number;
    Redirect?: true;
  }

  interface AutocompleteCategoryItem extends SearchItem {
    Type: 'Category';
    Value: string;
    FacetValue: string;
    HitCount: number;
    FieldName: string;
    SuggestionGroup: string;
    ImageUrl?: string;
    Payload?: string;
    RedirectUrl?: string;
    Redirect?: true;
  }

  type AutocompleteItem =
    | AutocompletePopularSearchItem
    | AutocompleteFreetextItem
    | AutocompleteProductItem
    | AutocompleteCategoryItem;

  type AutocompleteResponse = AutocompleteItem[];

  interface FacetsResponse {
    Facets: FacetCategory[];
  }

  interface Facet {
    Value: string;
    HitCount: number;
    Term: string;
    Selected: boolean;
    Payload: string;
    Url: string;
    ImageUrl: string;
  }

  type FacetCategory = {
    FieldName: string;
    DisplayName: string;
    IsRanged: boolean;
    AlwaysShowCount: number;
    IsMultiselect: boolean;
    IsSingleValue: boolean;

    IsAffectedAutocomplete: boolean;

    Ranges: string[] | null;
    Values: Facet[] | null;
    Selection: Facet[] | null;

    TotalHits: number | null;
  } & ({ IsTree: true; TreeSeparator: string } | { IsTree: false; TreeSeparator: null });

  interface FitmentsResponseType {
    Fitment: FitmentField;
    Fields: string[] | null;
    Fitments: GenericItem[] | null;
  }

  interface SearchResponse {
    Items: SearchItem[];
    Actions: Action[];
    Facets: FacetCategory[];
    Query: string;
    OriginalQuery: string;
  }

  type GenericItem = {
    id?: string;
  } & { [key: string]: string | string[] | number | number[] | boolean };

  type SearchItem = {
    __docid?: string;
    __score?: string;
    id?: string;
    fitsTheVehicle?: string;
    variants?: GenericItem[];
  };
}

export enum FitmentField {
  vehicleSpecific = 'Vehicle Specific',
  manyFitments = 'Many Fitments',
  noFitmentData = 'No Fitment Data',
  universalFit = 'Universal Fit',
}
