import { createAction } from '@reduxjs/toolkit';

import type { AutocompleteItem, FacetValueBase } from 'Models/index.ts';

const autocompleteTypes = ['text' as const, 'product' as const, 'facetValue' as const];
export type AutocompleteTypes = (typeof autocompleteTypes)[number][];
export type AutocompleteResponse = {
  items: AutocompleteItem[] | null;
  query: string;
  totalHits: number;
};

export type AutocompleteRequest = {
  query: string;
  selection: FacetValueBase[];
};

export const changeAutocompleteVisibility = createAction<boolean>('CHANGE_AUTOCOMPLETE_VISIBILITY');
export const clearAutocompleteQuery = createAction('CLEAR_AUTOCOMPLETE_QUERY');
export const setAutocompleteQuery = createAction<string>('SET_AUTOCOMPLETE_QUERY');

export const sendAutocompleteRequest = createAction<AutocompleteRequest>('SEND_AUTOCOMPLETE_REQUEST');

export const setAutocompleteResponse = createAction<AutocompleteResponse>('SET_AUTOCOMPLETE_RESPONSE');
export const setDefaultAutocompleteSuggestions = createAction<AutocompleteItem[]>(
  'SET_DEFAULT_AUTOCOMPLETE_SUGGESTIONS',
);

export const waitingForAutocompleteChanged = createAction<boolean>('WAITING_FOR_AUTOCOMPLETE_CHANGED');

export const havePreventedSearchChanged = createAction<boolean>('HAVE_PREVENTED_SEARCH_CHANGED');
